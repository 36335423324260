
import { defineComponent, computed, onMounted, ref } from "vue";
import RelationsTable from "@/components/individual/RelationsTable.vue";
import BeneficiaryDetails from "@/components/reusable/BeneficiaryDetails.vue";

import { useStore } from "vuex";
import { config } from "@/core/helpers/config";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";

export default defineComponent({
  name: "individual-client-profile",
  components: {
    RelationsTable,
    BeneficiaryDetails,
  },
  data() {
    return {
      profileSummary: [],
      hospitalSummary: [],
      employerSummary: [],
      relationsSummary: [],
      beneficiariesSummary: [],
      dataTable: null,
      avatarUrl: "",
      moiImage: "",
    };
  },
  setup() {
    const store = useStore();
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");

      setCurrentPageTitle("Profile Overview");
    });

    const user = computed(() => {
      return store.getters.currentUser;
    });

    /**
     * Reset config
     * @param event
     */
    const reset = (event) => {
      event.preventDefault();
      // remove existing saved config
      localStorage.removeItem("config");
      window.location.reload();
    };

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    return {
      tabIndex,
      config,
      reset,
      setActiveTab,
      user,
    };
  },
  mounted() {
    this.avatarUrl = variables.AVATAR_ROUTE;
    // Get user personal information on page load
    this.getProfileSummary();
  },
  created() {
    //Set page title
    document.title = "Profile Overview | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    //Fetch individual client profile personal infomation
    getProfileSummary() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.PROFILE_OVERVIEW_INFORMATION)
          .then((response) => {
            //Assign data to form fields
            this.profileSummary = response.data.data.personal.personal;
            this.hospitalSummary = response.data.data.personal.hospital;
            this.employerSummary = response.data.data.personal.employer;
            this.relationsSummary = response.data.data.relations;
            this.beneficiariesSummary = response.data.data.beneficiaries;
            if (
              response.data.data.personal.personal.identificationImage != ""
            ) {
              this.getMoiImage(
                response.data.data.personal.personal.identificationImage
              );
            }

            setTimeout(() => {
              //Add jQuery datatable
              $("#basicExample").DataTable({
                iDisplayLength: 10,
                language: {
                  searchPlaceholder: "Search...",
                  sSearch: "",
                  lengthMenu: "_MENU_ items/page",
                  // "lengthMenu": "Display _MENU_ records per page",
                  zeroRecords: "No matching records found",
                  // "info": "Showing page _PAGE_ of _PAGES_",
                  infoEmpty: "No records available",
                  infoFiltered: "(filtered from _MAX_ total records)",
                },
                processing: true,
              });
            }, 3000);
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getMoiImage(value) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.MEANS_OF_ID_IMAGE_ROUTE}${value}`)
          .then(({ data }) => {
            //Assign data to form fields
            this.moiImage = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
