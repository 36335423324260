
import { defineComponent } from "vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import variables from "@/router/api";
import { useStore } from "vuex";

export default defineComponent({
  name: "relations-table-component",
  components: {},
  props: {
    relationsSummary: Array
  },
  data() {
    return {
      avatarUrl: "",
      userRole: ""
    };
  },
  setup() {
    const store = useStore();
    const userType = store.getters.currentUser;

    return {
      userType
    };
  },
  mounted() {
    this.avatarUrl = variables.AVATAR_ROUTE;
    this.userRole = variables.INDIVIDUAL_CLIENT_USER_ROLE;
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    }
  }
});
