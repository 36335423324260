
import { defineComponent } from "vue";
import variables from "@/router/api";

export default defineComponent({
  name: "beneficiary-details-component",
  components: {},
  data() {
    return {
      avatarUrl: ""
    };
  },
  props: {
    beneficiariesSummary: Object
  },
  mounted() {
    this.avatarUrl = variables.AVATAR_ROUTE;
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    }
  }
});
